<script setup lang="ts">
import Container from '~/components/layout/Container.vue'
import Footer from '~/components/layout/footer/Footer.vue'
import Header from '~/components/layout/header/Header.vue'
import SidebarNavHolder from '~/components/layout/sidebar/SidebarNavHolder.vue'
import { useAsideMenuStore } from '~/stores/useAsideMenuStore'
import { useFancyHeaderStore } from '~/stores/useFancyHeaderStore'

const hasTransparentBackground = ref(false)
const fancyHeaderStore = useFancyHeaderStore()
const asideMenuStore = useAsideMenuStore()

function updateScrolledToTop() {
  hasTransparentBackground.value = window.scrollY <= 0
}

watch(
  () => fancyHeaderStore.value,
  (hasFancyHeaderValue) => {
    if (!process.client) {
      if (hasFancyHeaderValue) {
        hasTransparentBackground.value = true
      }

      return
    }

    if (hasFancyHeaderValue) {
      hasTransparentBackground.value = true

      document.addEventListener('scroll', updateScrolledToTop)

      nextTick(() => {
        updateScrolledToTop()
      })

      return
    }

    document.removeEventListener('scroll', updateScrolledToTop)
    hasTransparentBackground.value = false
  },
  { immediate: true }
)
</script>

<template>
  <div class="l-default">
    <header
      :class="[
        'l-default__header',
        hasTransparentBackground && 'l-default__header--transparent',
        fancyHeaderStore.value && 'l-default__header--fancy',
        asideMenuStore.isAsideMenuOpen && 'l-default__header--aside-open',
      ]"
    >
      <Container>
        <Header />
      </Container>
    </header>

    <SidebarNavHolder />

    <main class="l-default__main">
      <slot />
    </main>

    <footer class="l-default__footer">
      <Container>
        <Footer />
      </Container>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.l-default {
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  &__header {
    background-color: var(--color-primaryDark);
    position: sticky;
    top: -1px;
    z-index: 200;
    border-bottom: 1px solid
      hsla(
        var(--color-light-h),
        var(--color-light-s),
        var(--color-light-l),
        0.08
      );

    &--fancy {
      transition: all var(--transition-duration)
        var(--transition-timing-function);
    }

    &--transparent {
      background-color: hsla(
        var(--color-primaryDark-h),
        var(--color-primaryDark-s),
        var(--color-primaryDark-l),
        0.1
      );
      backdrop-filter: blur(4px);
    }

    &--aside-open {
      background-color: hsla(
        var(--color-primaryDark-h),
        var(--color-primaryDark-s),
        var(--color-primaryDark-l),
        1
      );
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__footer {
    margin-top: auto;
    border-top: 2px solid var(--color-dark);
  }
}
</style>
