<script setup lang="ts">
import HeaderActions from './HeaderActions.vue'
import HeaderMenuToggle from './HeaderMenuToggle.vue'
import HeaderNav from './HeaderNav.vue'
import LanguageSwitcher from './LanguageSwitcher.vue'

const indexLink = useLocaleLink({ name: 'index' })
</script>

<template>
  <div class="c-header">
    <div class="c-header__menu">
      <HeaderMenuToggle />
    </div>

    <div class="c-header__logo">
      <NuxtLink :to="indexLink" class="c-link c-link--inherit">{{
        $t('header.logoText')
      }}</NuxtLink>
    </div>

    <div class="c-header__nav">
      <HeaderNav />
    </div>

    <div class="c-header__actions">
      <HeaderActions />
    </div>

    <div class="c-header__language">
      <LanguageSwitcher />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@inkline/inkline/css/mixins' as *;

.c-header {
  height: var(--header-height);
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 8px;
  align-items: center;

  @include breakpoint-down('sm') {
    gap: 0;
  }

  &__menu {
    display: none;

    @include breakpoint-down('xl') {
      display: flex;
      margin-right: -8px;
      margin-left: -16px;
    }
    @include breakpoint-down('sm') {
      margin-left: -16px;
    }
  }

  &__logo {
    font-size: 32px;
    line-height: 0.8;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: var(--color-white);
    padding-bottom: 2px;

    @include breakpoint-down('md') {
      font-size: 24px;
    }

    @include breakpoint-down('sm') {
      font-size: 20px;
      letter-spacing: -0.4px;
    }
  }

  &__nav {
    display: flex;

    @include breakpoint-down('xl') {
      display: none;
    }
  }

  &__language {
    position: relative;

    @include breakpoint-down('sm') {
      padding-left: 1px;

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: var(--color-light);
        opacity: 0.1;
        height: 20px;
        margin: auto;
      }
    }
  }
}
</style>
