<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import { useNavLinks } from '~/composables/nav/navLinks'

const navLinks = useNavLinks()

const onNavItemClick = () => {
  dropdownVisible.value = false
}

const dropdownVisible = ref(false)
</script>

<template>
  <div class="c-header-nav">
    <IDropdown
      v-model:visible="dropdownVisible"
      placement="bottom-start"
      @click:outside="dropdownVisible = false"
    >
      <template #default>
        <button class="c-header-nav__item c-header-nav__item--has-children">
          {{ navLinks.offers.label }}
          <Icon name="chevron-down" />
        </button>
      </template>

      <template #body>
        <template v-for="item in navLinks.offers.items" :key="item.label">
          <NuxtLink :to="item.to" @click="onNavItemClick">
            {{ item.label }}
          </NuxtLink>
        </template>
      </template>
    </IDropdown>

    <template v-for="item in navLinks.other.items" :key="item.label">
      <NuxtLink :to="item.to" class="c-header-nav__item">
        {{ item.label }}
      </NuxtLink>
    </template>
  </div>
</template>

<style scoped lang="scss">
.c-header-nav {
  display: flex;
  margin: auto;

  &__item {
    font-size: 17px;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    align-items: center;
    padding: 12px;
    text-decoration: none;
    transition-property: all;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-timing-function);
    background-color: transparent;
    border: none;

    &.router-link-active {
      color: var(--color-secondary);
    }

    &--has-children {
      gap: 8px;

      .ico {
        font-size: 7px;
        top: 1px;
      }
    }

    &:hover,
    &:focus-visible {
      color: var(--color-secondary);
    }
  }

  :deep(.dropdown-wrapper) {
    &:has(.router-link-active) .c-header-nav__item {
      color: var(--color-secondary);
    }
  }

  // TODO: Add to inkline config
  :deep(.dropdown) {
    --dropdown--border-top-left-radius: 6px;
    --dropdown--border-top-right-radius: 6px;
    --dropdown--border-bottom-left-radius: 6px;
    --dropdown--border-bottom-right-radius: 6px;
    --dropdown--body--padding: 20px 28px;

    a {
      color: var(--color-dark);
      padding: 4px;
      font-weight: 500;
      font-size: 17px;
      transition-property: all;
      transition-duration: var(--transition-duration);
      transition-timing-function: var(--transition-timing-function);
      text-decoration: none;

      &.router-link-active {
        color: var(--color-secondary-700);
      }

      &:hover,
      &:focus-visible {
        color: var(--color-secondary-700);
      }
    }
  }
}
</style>
