<script setup>
import Icon from '~/components/global/Icon.vue'

const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value)
})
</script>

<template>
  <IButton
    v-for="availableLocale in availableLocales"
    :key="availableLocale.code"
    outline
    color="light"
    :href="switchLocalePath(availableLocale.code)"
    class="c-language-switcher"
  >
    <Icon name="globe" />

    {{ availableLocale.code }}
  </IButton>
</template>

<style scoped lang="scss">
@use '@inkline/inkline/css/mixins' as *;

.button.c-language-switcher {
  --button--padding-top: 12px;
  --button--padding-bottom: 12px;
  --button--padding-left: 16px;
  --button--padding-right: 16px;

  min-height: 50px;
  border: none;

  :deep(.button-content) {
    gap: 4px;
    text-transform: uppercase;
  }

  @include breakpoint-down('sm') {
    .ico {
      display: none;
    }
  }
}
</style>
