<script setup lang="ts">
import { storeToRefs } from 'pinia'
import SidebarContent from './SidebarContent.vue'
import { useAsideMenuStore } from '~/stores/useAsideMenuStore'

const asideMenuStore = useAsideMenuStore()
const { isAsideMenuOpen } = storeToRefs(asideMenuStore)

const isOpened = ref(false)

const onUpdate = (value: boolean) => {
  isOpened.value = value
  asideMenuStore.toggle(value)
}

watch(isAsideMenuOpen, (value) => {
  isOpened.value = value
})
</script>

<template>
  <ISidebar
    :model-value="isOpened"
    :collapse="true"
    class="c-sidebar-holder"
    @update:model-value="onUpdate"
  >
    <SidebarContent />
  </ISidebar>
</template>

<style lang="scss" scoped>
.c-sidebar-holder.sidebar-wrapper {
  --sidebar--width: 275px;
  --sidebar--padding: 0;
  --sidebar--border-width: 0;

  top: var(--header-height);
  left: 0;
  position: fixed;
  height: calc(100dvh - var(--header-height));
  min-height: unset;
}
</style>
