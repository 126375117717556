interface NavItem {
  label: string
  to: string | ReturnType<typeof useLocaleLink>
}

interface Nav {
  label: string
  items: NavItem[]
}

export function useNavLinks() {
  const { t } = useI18n()

  return {
    offers: {
      label: t('nav.offersLabel'),
      items: [
        {
          label: t('nav.items.offices'),
          to: getEstateListingLink({
            estateType: 'office',
          }),
        },
        {
          label: t('nav.items.warehouse'),
          to: getEstateListingLink({
            estateType: 'warehouse',
          }),
        },
        {
          label: t('nav.items.retail'),
          to: getEstateListingLink({
            estateType: 'retail',
          }),
        },
      ],
    },
    other: {
      label: t('nav.otherLabel'),
      items: [
        {
          label: t('nav.items.ourServices'),
          to: useLocaleLink({ name: 'our-services' }),
        },
        {
          label: t('nav.items.references'),
          to: useLocaleLink({ name: 'references' }),
        },
        {
          label: t('nav.items.aboutUs'),
          to: useLocaleLink({ name: 'about-us' }),
        },
        { label: t('nav.items.news'), to: useLocaleLink({ name: 'news' }) },
        {
          label: t('nav.items.contact'),
          to: useLocaleLink({ name: 'contact' }),
        },
      ],
    },
  } as {
    offers: Nav
    other: Nav
  }
}
