<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import { useNavLinks } from '~/composables/nav/navLinks'
import { footerAdditionalLinksBox } from '#nuxt-prepare'

const { locale } = useI18n()
const navLinks = useNavLinks()
</script>

<template>
  <div class="c-footer-links">
    <div class="c-footer-links__item">
      <strong class="sc-title">
        {{ navLinks.offers.label }}
      </strong>

      <div class="sc-items sc-items--offers">
        <template v-for="item in navLinks.offers.items" :key="item.label">
          <NuxtLink class="c-link c-link--inherit" :to="item.to">
            {{ item.label }}
          </NuxtLink>
        </template>
      </div>
    </div>

    <div class="c-footer-links__item">
      <strong class="sc-title">
        {{ navLinks.other.label }}
      </strong>

      <div class="sc-items sc-items--other">
        <template v-for="item in navLinks.other.items" :key="item.label">
          <NuxtLink class="c-link c-link--inherit" :to="item.to">
            <span>{{ item.label }}</span>
          </NuxtLink>
        </template>

        <template
          v-for="boxItem in footerAdditionalLinksBox?.[
            locale as keyof typeof footerAdditionalLinksBox
          ]?.box_items"
          :key="boxItem.id"
        >
          <NuxtLink
            class="c-link c-link--inherit"
            :to="boxItem.url"
            :target="
              boxItem.module_data.custom_is_external === '1'
                ? '_blank'
                : '_self'
            "
          >
            <span>{{ boxItem.name }}</span>
            <Icon
              v-if="boxItem.module_data.custom_is_external === '1'"
              name="link-external"
            />
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-footer-links {
  display: flex;
  gap: 24px;
  width: fit-content;
  margin: 32px auto 0;

  @include breakpoint-down('lg') {
    margin-top: 0;
  }

  @include breakpoint-down('md') {
    width: auto;
  }

  @include breakpoint-down('xs') {
    flex-direction: column;
  }

  &__item {
    min-width: 240px;

    @include breakpoint('xl') {
      min-width: 200px;
    }

    .sc-title {
      display: block;
      margin-bottom: 8px;

      @include breakpoint-down('xs') {
        margin-bottom: 6px;
      }
    }

    .sc-items {
      &--other {
        columns: 2;
        column-gap: 24px;

        @include breakpoint-down('xl') {
          column-gap: 12px;
        }
      }

      .c-link {
        padding: 8px 0;
        line-height: 1;
        display: flex;
        gap: 6px;
        align-items: center;

        .ico {
          font-size: 10px;
          color: var(--color-primary);
          align-self: start;
          margin-top: 2px;
        }

        @include breakpoint-down('xs') {
          padding: 6px 0;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
