import { defineStore } from 'pinia'

export const useAsideMenuStore = defineStore('asideMenu', () => {
  const isAsideMenuOpen = ref(false)

  const toggle = (value = null as boolean | null) => {
    if (value !== null) {
      isAsideMenuOpen.value = value
    } else {
      isAsideMenuOpen.value = !isAsideMenuOpen.value
    }

    return isAsideMenuOpen.value
  }

  // disable scroll on body
  watch(isAsideMenuOpen, (value) => {
    document.body.style.overflow = value ? 'hidden' : 'auto'
  })

  return {
    isAsideMenuOpen,
    toggle,
  }
})
