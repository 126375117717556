<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import { usePrivacyPolicyHref } from '~/composables/usePrivacyPolicyHref'

const consentHref = usePrivacyPolicyHref()
</script>

<template>
  <div class="c-footer-other">
    <div class="c-footer-other__links">
      <a :href="consentHref" target="_blank" class="c-link c-link--inherit">{{
        $t('footer.other.privacyPolicyLabel')
      }}</a>

      <div class="sc-divider" />

      <button class="c-link c-link--inherit" onclick="Cookiebot.show()">
        {{ $t('footer.other.cookieSettingsLabel') }}
      </button>
    </div>

    <div class="c-footer-other__social">
      <div class="sc-divider" />

      <div class="sc-links">
        <NuxtLink
          href="https://www.facebook.com/CBRESlovakia/"
          target="_blank"
          class="c-link"
          title="Facebook"
        >
          <Icon name="social-facebook" />
        </NuxtLink>

        <NuxtLink
          href="https://www.linkedin.com/company/cbre-slovakia/?originalSubdomain=sk"
          target="_blank"
          class="c-link"
          title="LinkedIn"
        >
          <Icon name="social-linkedin" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-footer-other {
  display: flex;
  align-items: center;

  @include breakpoint-down('xs') {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .sc-divider {
    margin: auto 16px;
    height: 9px;
    width: 1px;
    background-color: var(--color-border);
  }

  &__links {
    display: flex;
    font-size: 16px;
  }

  &__social {
    display: flex;
    font-size: 16px;

    @include breakpoint-down('xs') {
      .sc-divider {
        display: none;
      }
    }

    .sc-links {
      display: flex;
      gap: 8px;
    }

    .c-link {
      padding: 6px;

      .ico {
        font-size: 20px;
      }
    }
  }
}
</style>
