<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import useQuickOfferModalStore from '~/stores/modals/useQuickOfferModal'
import useFavoritesStore from '~/stores/useFavoritesStore'

const quickOfferModalStore = useQuickOfferModalStore()
const favoritesStore = useFavoritesStore()
</script>

<template>
  <div class="c-header-actions">
    <div class="c-header-actions__desktop">
      <IButton color="secondary" @click="quickOfferModalStore.open()">{{
        $t('header.actions.quickOffer')
      }}</IButton>

      <IButton
        :to="useLocaleLink('favorites')"
        class="sc-favorites"
        color="light"
        outline
      >
        <span v-if="favoritesStore.itemsCount > 0" class="sc-count">{{
          favoritesStore.itemsCount
        }}</span>
        <Icon name="heart" />
      </IButton>
    </div>

    <div class="c-header-actions__phone">
      <IButton color="secondary" outline @click="quickOfferModalStore.open()">
        <Icon name="header-contact" />
      </IButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@inkline/inkline/css/mixins' as *;
.c-header-actions {
  .button {
    --button--padding-left: 32px;
    --button--padding-right: 32px;
    min-height: 50px;
  }

  &__desktop {
    display: flex;
    gap: 8px;

    @include breakpoint-down('sm') {
      display: none;
    }

    .sc-favorites {
      --button--font-size: 16px;
      --button--padding-left: 16px;
      --button--padding-right: 16px;

      position: relative;
      border-color: hsla(
        var(--color-light-h),
        var(--color-light-s),
        var(--color-light-l),
        0.5
      );

      .sc-count {
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 12px;
        width: auto;
        height: 18px;
        padding: 0 4px;
        min-width: 18px;
        border-radius: 9px;
        display: grid;
        place-items: center;
        color: var(--color-light);
        background-color: var(--color-red);
      }
    }
  }

  &__phone {
    display: none;

    @include breakpoint-down('sm') {
      display: flex;
    }

    .button {
      --button--padding-left: 16px;
      --button--padding-right: 16px;
      --button--border-width: 0;

      .ico {
        font-size: 20px;
      }
    }
  }
}
</style>
