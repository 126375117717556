<script setup lang="ts"></script>

<template>
  <div class="c-footer-contact">
    <strong class="c-footer-contact__label">{{
      $t('footer.contactLabel')
    }}</strong>

    <div class="c-footer-contact__availability">
      <span>{{ getTextConfiguration('general.contact_days_short') }}</span>
      <span>{{ getTextConfiguration('general.contact_hours') }}</span>
    </div>

    <div class="c-footer-contact__phone">
      <a
        class="c-link c-link--secondary"
        :href="`tel:${getTextConfiguration('general.phone')}`"
        >{{ getTextConfiguration('general.phone') }}</a
      >
    </div>

    <div class="c-footer-contact__email">
      <a
        class="c-link c-link--underlined-bold"
        :href="`mailto:${getTextConfiguration('general.email')}`"
        >{{ getTextConfiguration('general.email') }}</a
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@inkline/inkline/css/mixins' as *;

.c-footer-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-top: 32px;

  @include breakpoint-down('lg') {
    margin-top: 0;
  }

  @include breakpoint-down('md') {
    align-items: flex-start;
    text-align: left;
  }

  &__availability {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    @include breakpoint-down('xs') {
      margin-bottom: 8px;
    }
  }

  &__phone {
    .c-link {
      font-family: var(--font-family-secondary-base);
      font-size: 32px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  &__email {
    .c-link {
      --underline-height: 1px;

      display: block;
      font-weight: 600;
    }
  }
}
</style>
