<script setup lang="ts">
const { locale } = useI18n()

const captchaPrivacyLink = `https://policies.google.com/privacy?hl=${locale.value}`
const captchaTermsLink = `https://policies.google.com/terms?hl=${locale.value}`
const rieseniaLink =
  locale.value === 'sk' ? 'https://riesenia.com' : 'https://riesenia.com/en'
</script>

<template>
  <div class="c-footer-bottom">
    <i18n-t
      scope="global"
      keypath="footer.captcha.base"
      tag="div"
      class="c-footer-bottom__captcha"
    >
      <template #_0>
        <a
          class="c-link c-link--inherit c-link--underlined"
          :href="captchaPrivacyLink"
          target="_blank"
          >{{ $t('footer.captcha.0') }}</a
        >
      </template>

      <template #_1>
        <a
          class="c-link c-link--inherit c-link--underlined"
          :href="captchaTermsLink"
          target="_blank"
          >{{ $t('footer.captcha.1') }}</a
        >
      </template>
    </i18n-t>

    <i18n-t
      keypath="footer.author"
      tag="div"
      class="c-footer-bottom__riesenia"
      scope="global"
    >
      <template #_0>
        <a
          class="c-link c-link--underlined-bold"
          :href="rieseniaLink"
          target="_blank"
          >RIESENIA</a
        >
      </template>
    </i18n-t>
  </div>
</template>

<style scoped lang="scss">
@use '@inkline/inkline/css/mixins' as *;

.c-footer-bottom {
  display: flex;
  gap: 32px;
  padding: 16px 0;
  min-height: 80px;
  border-top: 1px solid var(--color-border);
  font-size: 16px;
  line-height: 1.32;

  @include breakpoint-down('xl') {
    flex-direction: column;
    gap: 8px;
    min-height: 0;
  }
  @include breakpoint-down('xs') {
    gap: 16px;
  }

  &__captcha {
    flex: 1 1 auto;
    color: var(--color-solid-grey-dark);

    @include breakpoint-down('xl') {
      font-size: 13px;
    }
  }

  &__riesenia {
    flex: 0 0 auto;
    color: var(--color-solid-grey-dark);

    .c-link {
      font-weight: 600;
    }
  }
}
</style>
