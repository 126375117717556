<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import { useNavLinks } from '~/composables/nav/navLinks'
import useQuickOfferModalStore from '~/stores/modals/useQuickOfferModal'
import { useAsideMenuStore } from '~/stores/useAsideMenuStore'
import useFavoritesStore from '~/stores/useFavoritesStore'

const navLinks = useNavLinks()
const { toggle: asideMenuToggle } = useAsideMenuStore()
const quickOfferModalStore = useQuickOfferModalStore()
const favoritesStore = useFavoritesStore()
</script>

<template>
  <div class="c-sidebar-content">
    <div class="c-sidebar-content__offers">
      <strong class="sc-title">
        {{ navLinks.offers.label }}
      </strong>

      <div class="sc-items">
        <template v-for="item in navLinks.offers.items" :key="item.label">
          <NuxtLink :to="item.to" @click="asideMenuToggle(false)">
            {{ item.label }}
          </NuxtLink>
        </template>
      </div>
    </div>

    <div class="c-sidebar-content__other">
      <template v-for="item in navLinks.other.items" :key="item.label">
        <NuxtLink
          v-if="!item.isExternal"
          :to="item.to"
          @click="asideMenuToggle(false)"
        >
          {{ item.label }}
        </NuxtLink>
      </template>
    </div>

    <div class="c-sidebar-content__favorites">
      <NuxtLink
        :to="useLocaleLink('favorites')"
        class="c-link c-link--has-icon"
        @click="asideMenuToggle(false)"
      >
        <Icon name="heart" />
        <span>{{ $t('sidebar.favorites') }}</span>
        <span v-if="favoritesStore.itemsCount" class="sc-count">{{
          favoritesStore.itemsCount
        }}</span>
      </NuxtLink>
    </div>

    <div class="c-sidebar-content__quick-offer">
      <IButton color="secondary" @click="quickOfferModalStore.open()">{{
        $t('header.actions.quickOffer')
      }}</IButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-sidebar-content {
  padding: 40px 20px;
  flex: 0 0 auto;

  a {
    padding: 8px 0;
    line-height: 1;
    font-weight: 500;

    &.router-link-active {
      color: var(--link--hover--color);
    }

    .sc-count {
      font-size: 12px;
      width: auto;
      height: 18px;
      padding: 0 4px;
      min-width: 18px;
      border-radius: 9px;
      display: grid;
      place-items: center;
      color: var(--color-light);
      background-color: var(--color-red);
    }
  }

  &__offers {
    display: flex;
    flex-direction: column;

    .sc-title {
      color: var(--color-solid-grey-dark);
      margin-bottom: 12px;
    }

    .sc-items {
      display: flex;
      flex-direction: column;
    }
  }

  &__other {
    border-top: solid 1px var(--color-border);
    margin-top: 32px;
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0 10px;
  }

  &__favorites {
    display: none;

    @include breakpoint-down('sm') {
      border-top: solid 1px var(--color-border);
      margin-top: 32px;
      padding-top: 32px;
      display: flex;
      flex-direction: column;
    }
  }

  &__quick-offer {
    display: none;

    @include breakpoint-down('sm') {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
~/composables/nav/navLinks
