<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAsideMenuStore } from '~/stores/useAsideMenuStore'

const asideMenuStore = useAsideMenuStore()
const { isAsideMenuOpen } = storeToRefs(asideMenuStore)

const isOpened = ref(false)

const onUpdate = (value: boolean) => {
  isOpened.value = value
  asideMenuStore.toggle(value)
}

watch(isAsideMenuOpen, (value) => {
  isOpened.value = value
})
</script>

<template>
  <IHamburgerMenu
    class="c-header-menu-toggle"
    :model-value="isOpened"
    color="light"
    @update:model-value="onUpdate"
  />
</template>

<style scoped lang="scss">
.c-header-menu-toggle.hamburger-menu {
  --hamburger-menu--opacity: 1;
  --hamburger-menu--padding: 16px;
  --hamburger-menu--bar--width: 20px;
  --hamburger-menu--bar--height: 2px;
  --hamburger-menu--bar--spacing: 4px;
  --hamburger-menu--bar--border-radius: 0;
}
</style>
