import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "100",
  height: "25",
  fill: "none",
  viewBox: "0 0 100 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#003F2D",
      d: "M41.444 19.138h-8.247v-4.173h8.482c1.05.124 1.852 1.006 1.852 2.05-.025 1.13-.963 2.11-2.087 2.123m-8.247-13.71h8.692c.975.173 1.691 1.005 1.691 1.986 0 1.006-.765 1.95-1.777 2.087l-8.618.012zm12.272 6.668c3.16-1.055 3.728-3.725 3.728-5.899C49.198 2.87 46.865 0 39.963 0H27.086v24.95h12.84c6.938 0 9.494-3.576 9.494-7.178-.013-4.334-3.95-5.676-3.95-5.676M78.247 0v25H100v-5.7H84.296v-4.36h14.148V9.476H84.296l-.012-4.024h15.691L100 0zm-9.37 7.774c-.112.82-.926 1.714-1.939 1.714h-8.494V5.502h8.494c1.013 0 1.815.745 1.938 1.738zM65.58.037H52.432V25h6.037V14.903h6.655c1.9.025 3.382 1.602 3.382 3.54v6.545h5.914l-.013-8.06c0-3.739-3.58-4.832-3.58-4.832s3.667-1.105 3.667-5.514c0-5.415-4.618-6.545-8.914-6.545M23.16 18.964c-.086 0-8.259.162-11.074-.111-4.543-.448-6.148-3.838-6.148-6.595 0-3.44 2.334-5.986 5.95-6.458 1.717-.224 11.124-.124 11.236-.124h.197L23.333 0h-.197L12.482.025C11.296.099 9.333.21 7.32 1.043a12.7 12.7 0 0 0-4.877 3.962A12.5 12.5 0 0 0 0 12.445c0 .844.062 1.688.198 2.495.765 3.788 3.234 6.918 6.765 8.595 1.197.534 3.037 1.167 6.753 1.378l2 .037 7.42.013h.197l.025-6.011z"
    }, null, -1)
  ])))
}
export default { render: render }